import ClearIcon from "@mui/icons-material/Clear"
import { Box, Fade, FormHelperText, IconButton, SxProps, TextField, Theme } from "@mui/material"
import { useIsMobile } from "@recall/common"
import { FC, KeyboardEventHandler, PropsWithChildren, useEffect, useRef } from "react"

interface Props {
    onChange: Function
    onPressEnter?: Function
    value: string
    placeholder?: string
    error?: string
}

export const SEARCH_INPUT_ID = "id-search-input"

const SearchInputComp: FC<PropsWithChildren<Props>> = ({
    onChange,
    onPressEnter,
    value,
    placeholder,
    error,
}) => {
    const inputRef = useRef(null)
    const isMobile = useIsMobile()

    useEffect(() => {
        if (inputRef.current && !isMobile) {
            inputRef.current.focus()
        }
    }, [isMobile])

    const handleOnKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.key === "Enter") {
            onPressEnter()
        }
    }

    return (
        <Box sx={{ position: "relative", mb: 1 }}>
            <TextField
                id={SEARCH_INPUT_ID}
                sx={styles.search}
                value={value}
                onKeyDown={handleOnKeyDown}
                variant="outlined"
                onChange={(e) => onChange(e.target.value)}
                fullWidth
                inputProps={{ sx: styles.inputField }}
                InputProps={{
                    sx: styles.input,
                    inputRef: inputRef,
                    endAdornment: value ? (
                        <IconButton
                            aria-label="clear search"
                            onClick={() => onChange("")}
                            edge="end"
                            size="small"
                            sx={{ mr: 1 }}
                        >
                            <ClearIcon />
                        </IconButton>
                    ) : null,
                }}
                data-testid="cards-search"
                autoComplete="off"
                autoFocus={!isMobile}
                placeholder={placeholder}
                error={Boolean(error)}
            />
            <Fade in={Boolean(error)}>
                <FormHelperText error={Boolean(error)} sx={styles.helperText}>
                    {error}
                </FormHelperText>
            </Fade>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    search: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                border: "none",
            },
            "&:hover fieldset": {
                border: "none",
            },
            "&.Mui-focused fieldset": {
                border: "none",
            },
        },
    },
    input: {
        borderRadius: 1.5,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        "& ::placeholder": {
            fontSize: { xs: 12, md: 14 },
        },
    },
    inputField: {
        py: { xs: 2, md: 3 },
        px: { xs: 2.5, md: 2.5 },
    },
    helperText: {
        position: "absolute",
        left: 0,
        right: 0,
        margin: 0,
        marginTop: 0.5,
    },
}

export default SearchInputComp
