import { useItemsCount } from "hooks/items/useItemsCount"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { USAGE_SURVEY } from "../constants/surveys"
import { isImportModalOpen } from "storage/redux/app/selectors"

const MIN_ITEMS_COUNT_FOR_SURVEY = 3

export const useIsSurveyCompleted = () => {
    const surveys = useSelector((state: RootState) => state.user.surveys)
    const itemsCount = useItemsCount()
    const isImportOpen = useSelector(isImportModalOpen)

    return {
        shouldShowSurvey:
            !surveys?.includes(USAGE_SURVEY) &&
            !!itemsCount &&
            itemsCount >= MIN_ITEMS_COUNT_FOR_SURVEY &&
            !isImportOpen,
        isLoading: itemsCount === null,
    }
}
