import { Database } from "@nozbe/watermelondb"
import { useDatabase } from "@nozbe/watermelondb/react"
import { CONTACT_US_EMAIL, dbUtils, firebase, posthogService, RECALL_APP_URL } from "@recall/common"
import { SIGNUP_EVENT } from "constants/events"
import {
    createUserWithEmailAndPassword as createUserWithEmailAndPasswordFirebase,
    User as FirebaseUser,
    getAdditionalUserInfo,
    GoogleAuthProvider,
    OAuthProvider,
    sendPasswordResetEmail as sendPasswordResetEmailFirebase,
    signInWithCredential,
    signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
    signOut as signOutFirebase,
    UserCredential,
} from "firebase/auth"
import { toast } from "react-toastify"
import { functions } from "services/firebase/firebaseFunction"
import { referralService } from "services/referralService"
import { LOGOUT_ACTION } from "storage/redux/rootReducer"
import store from "storage/redux/store"
import { authErrorCodeMap } from "./errors"
import { dispatchClear } from "./useClearOnLogout"
import { useExtensionAuth } from "./useExtensionAuth"
import { GTMService } from "services/GTMService"

const actionCodeSettings = {
    url: `${RECALL_APP_URL}/items/`,
    handleCodeInApp: true,
}

export const useAuth = () => {
    const db = useDatabase()

    const extensionAuth = useExtensionAuth()

    const handleErrorMessage = (error_code: string) => {
        let message = `Something went wrong. Please contact support. ${CONTACT_US_EMAIL}`

        if (error_code in authErrorCodeMap) {
            message = authErrorCodeMap[error_code]
        }

        toast(message, { type: "error" })
    }

    const signOut = async () => {
        await logout(db)
    }

    const sendPasswordResetEmail = async (email: string) => {
        await sendPasswordResetEmailFirebase(firebase.auth, email, actionCodeSettings)
    }

    const signInUserWithAppleIdToken = async ({
        token,
        nonce,
    }: {
        token: string
        nonce: string
    }) => {
        const credential = new OAuthProvider("apple.com").credential({
            idToken: token,
            rawNonce: nonce,
        })
        await signInWithCredential(firebase.auth, credential)
    }

    const signInUserWithIdToken = async (token: string) => {
        const googleCredential = GoogleAuthProvider.credential(token)
        await signInWithCredential(firebase.auth, googleCredential)
    }

    const executeLogin = async (
        loginAction: () => Promise<FirebaseUser | null>,
        errorHandler?: (error: any) => void
    ) => {
        try {
            extensionAuth.isLoggingIn.current = true
            const user = await loginAction()
            extensionAuth.isLoggingIn.current = false
            return user || null
        } catch (error) {
            if (errorHandler) {
                errorHandler(error)
            }
            extensionAuth.isLoggingIn.current = false
            return null
        }
    }

    const createUserWithEmailAndPassword = async (email: string, password: string) => {
        return executeLogin(
            async () => {
                const isEmailDeleted = await checkIsEmailDeleted(email)

                if (isEmailDeleted) return null

                const credential = await createUserWithEmailAndPasswordFirebase(
                    firebase.auth,
                    email,
                    password
                )

                await referralService.createReferralUsed(credential.user.uid)
                posthogService.captureEvent(SIGNUP_EVENT)
                GTMService.trackSignup()
                await extensionAuth.loginWithEmailAndPassword(email, password)
                await functions.sendEmailVerification()
                return credential.user
            },
            (error) => handleErrorMessage(error.code)
        )
    }

    const signInWithEmailAndPassword = async (email: string, password: string) => {
        return await executeLogin(
            async () => {
                const credential = await signInWithEmailAndPasswordFirebase(
                    firebase.auth,
                    email,
                    password
                )
                await extensionAuth.loginWithEmailAndPassword(email, password)
                return credential.user
            },
            (error) => {
                throw error
            }
        )
    }

    const signInWithGooglePopup = async () => {
        return signInWithProvider(firebase.signinWithPopup)
    }

    const signInWithApple = async () => {
        return signInWithProvider(firebase.signinWithApple)
    }

    const signInWithProvider = async (getCredential: () => Promise<UserCredential>) => {
        return executeLogin(
            async () => {
                const credential = await getCredential()

                const { isNewUser } = getAdditionalUserInfo(credential)

                if (isNewUser) {
                    const isEmailDeleted = await checkIsEmailDeleted(credential.user.email)

                    if (isEmailDeleted) {
                        await credential.user.delete()
                        return null
                    }

                    posthogService.captureEvent(SIGNUP_EVENT)
                    GTMService.trackSignup()
                    await referralService.createReferralUsed(credential.user.uid, true)
                }

                await extensionAuth.loginWithCredential(credential)

                return credential.user
            },
            (error) => handleErrorMessage(error.code)
        )
    }

    return {
        signOut,
        createUserWithEmailAndPassword,
        signInWithGooglePopup,
        signInWithEmailAndPassword,
        sendPasswordResetEmail,
        signInUserWithIdToken,
        signInUserWithAppleIdToken,
        signInWithApple,
    }
}

export const logout = async (db: Database) => {
    store.dispatch({ type: LOGOUT_ACTION })
    await signOutFirebase(firebase.auth)
    setTimeout(() => {
        dbUtils.deleteDatabase(db)
    })
    dispatchClear()
}

const checkIsEmailDeleted = async (email: string) => {
    const { data: isEmailDeletedRecently } = await functions.isEmailDeletedRecently({
        email,
    })

    if (isEmailDeletedRecently) {
        toast(
            `This email address has been deleted recently. Please contact support if you would like to create another account. ${CONTACT_US_EMAIL}`,
            {
                type: "error",
                autoClose: false,
            }
        )
        return true
    }

    return false
}
