import { Box, SxProps, Theme, Typography } from "@mui/material"
import { FC, ReactNode } from "react"

interface Props {
    title: string
    supportedSources: { icon: ReactNode; label: string }[]
}

export const SupportList: FC<Props> = ({ title, supportedSources }) => {
    return (
        <Box sx={styles.container}>
            <Typography variant="body2" fontWeight={400} lineHeight="normal" pb={1.25}>
                {title}
            </Typography>
            {supportedSources.map((item, index) => (
                <Box key={index} sx={styles.iconContainer}>
                    {item.icon}
                    <Typography
                        variant="body2"
                        fontWeight={400}
                        lineHeight={"normal"}
                        sx={styles.text}
                    >
                        {item.label}
                    </Typography>
                </Box>
            ))}
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        borderRadius: 1,
        pt: 3,
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        gap: 1.25,
        py: 1,
        svg: {
            path: {
                fill: (theme) =>
                    theme.palette.mode === "dark"
                        ? theme.palette.text.secondary
                        : theme.palette.text.primary,
            },
        },
    },
}
