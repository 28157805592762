import { Article, Google } from "@mui/icons-material"
import { Avatar, Box, Divider, SxProps, Theme, Typography } from "@mui/material"
import { SearchPreview } from "@recall/common/src/api"
import { useIsMobile } from "@recall/common/src/hooks/useIsMobile"
import { isGoogleSearchUrl } from "@recall/common/src/utils"
import { FC, memo, useEffect, useState } from "react"
import { summariesApi } from "services/api"

interface Props {
    url: string
    handleSubmit: () => void
}

export const URLSearchPreview: FC<Props> = memo(({ url, handleSubmit }) => {
    const [result, setResult] = useState<SearchPreview | null>(null)
    const isMobile = useIsMobile()

    useEffect(() => {
        getResult()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getResult = async () => {
        try {
            const result = await summariesApi.getSummaryPreview(url)
            setResult(result)
        } catch (err) {
            setResult({ title: "", image: "", description: "" })
        }
    }

    return (
        <Box onClick={handleSubmit} sx={styles.root}>
            <Typography
                variant="body1"
                display="flex"
                alignItems="center"
                justifyContent="center"
                pb={1}
            >
                {isMobile ? "Press" : "Click"} to summarize
            </Typography>
            <Divider />
            {result?.title ? (
                <Box display="flex" alignItems="center" mt={1} mb={0.5} gap={1} width="100%">
                    <Avatar
                        src={isGoogleSearchUrl(url) ? undefined : result?.image}
                        variant="square"
                        sx={styles.avatar}
                    >
                        {isGoogleSearchUrl(url) ? (
                            <Google fontSize="large" sx={styles.icon} />
                        ) : (
                            <Article fontSize="large" sx={styles.icon} />
                        )}
                    </Avatar>
                    <Box sx={styles.textContainer}>
                        <Typography sx={styles.title}>{result?.title}</Typography>
                        {result?.description && (
                            <Typography variant="body2" sx={styles.description}>
                                {result?.description}
                            </Typography>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box display="flex" alignItems="center" py={1} gap={1} width="100%">
                    <Article fontSize="large" sx={styles.icon} />
                    <Typography pl={1} sx={styles.title}>
                        {url}
                    </Typography>
                </Box>
            )}
        </Box>
    )
})
const styles: Record<string, SxProps<Theme>> = {
    root: {
        width: "100%",
        p: 1,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "action.hover",
        },
    },
    avatar: {
        width: 90,
        height: 51,
        borderRadius: 0.5,
        backgroundColor: "action.hover",
    },
    icon: {
        color: "text.primary",
    },
    textContainer: {
        overflow: "hidden",
    },
    title: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: 600,
    },
    description: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        mb: 0.5,
    },
}
