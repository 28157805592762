import { Box, Button, SxProps, Theme } from "@mui/material"
import { useIsMobile } from "@recall/common"
import { FC } from "react"
import { ShortcutInfo } from "./ShortcutInfo"

interface Props {
    disabled?: boolean
    isLoading?: boolean
    okText?: string
    cancelText?: string
    onClick?: (...args: any[]) => void
    onClose?: (...args: any[]) => void
}

export const AddButtonFooter: FC<Props> = ({
    disabled,
    okText = "Create",
    cancelText = "Cancel",
    isLoading,
    onClick,
    onClose,
}) => {
    const isMobile = useIsMobile()

    return (
        <Box sx={styles.footer}>
            <ShortcutInfo />
            <Box sx={isMobile ? styles.mobileButtons : {}}>
                {onClose && (
                    <Button onClick={onClose} sx={styles.cancelButton}>
                        {cancelText}
                    </Button>
                )}
                {onClick && (
                    <Button
                        sx={styles.okButton}
                        onClick={onClick}
                        disabled={disabled || isLoading}
                        variant="contained"
                    >
                        {okText}
                    </Button>
                )}
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    footer: {
        position: { xs: "fixed", sm: "absolute" },
        bottom: 0,
        left: 0,
        right: 0,
        mt: 3.75,
        py: { xs: 3, sm: 3.75 },
        px: 4.5,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    mobileButtons: {
        display: "flex",
        justifyContent: { xs: "flex-end", sm: "space-between" },
        width: "100%",
    },
    cancelButton: {
        display: { xs: "none", sm: "inline-flex" },
        lineHeight: "normal",
        background: (theme) => theme.palette.divider,
        py: 1.5,
        px: 2.5,
        color: (theme) => theme.palette.text.primary,
        mr: 1.5,
        "&:hover": {
            background: (theme) => theme.palette.action.hover,
        },
    },
    okButton: {
        py: 1.5,
        px: 2.5,
        lineHeight: "normal",
    },
}
