import { Database } from "@nozbe/watermelondb"
import {
    ASSET_TYPES,
    assetRepository,
    ELEMENT_CUSTOM_IMAGE,
    firebase,
    getBase64Size,
    ItemModel,
    ITEMS,
    uploadBase64Image,
} from "@recall/common"
import { ELEMENT_IMAGE } from "@udecode/plate-media"

async function upsertBase64Asset(
    writer: Database,
    item: ItemModel,
    base64Image: string
): Promise<string> {
    const uid = firebase.auth.currentUser.uid
    const url = await uploadBase64Image(base64Image, uid)

    const existingAssets = await item.assets.fetch()
    const existingAsset = existingAssets.find((a) => a.url === base64Image)

    if (!existingAsset) {
        await assetRepository.create(writer, {
            item,
            url,
            size: getBase64Size(base64Image),
            type: ASSET_TYPES.IMAGE_URL,
        })
    } else {
        await existingAsset.update((record) => {
            record.url = url
        })
    }

    if (item.image === base64Image) {
        await item.update((record) => {
            record.image = url
        })
    }

    return url
}

async function extractBase64Images(db: Database, item: ItemModel, children: any[]): Promise<any[]> {
    const updatedChildren = await Promise.all(
        children.map(async (child) => {
            const url = getBase64ImageUrl(child)

            if (url) {
                const imageUrl = await upsertBase64Asset(db, item, url)
                if (child.type === ELEMENT_IMAGE) {
                    child.url = imageUrl
                } else {
                    child.urlOriginal = imageUrl
                    child.urlThumbnail = imageUrl
                    child.url_320 = imageUrl
                    child.url_1024 = imageUrl
                }
            }

            if (child?.children?.length) {
                child.children = await extractBase64Images(db, item, child.children)
            }

            return child
        })
    )

    return updatedChildren
}

const getBase64ImageUrl = (editorBlock: any) => {
    if (![ELEMENT_CUSTOM_IMAGE, ELEMENT_IMAGE].includes(editorBlock.type)) return null

    const url = editorBlock.options?.url || editorBlock.urlOriginal || editorBlock.url
    if (url?.startsWith("data:image/")) return url

    return null
}

export const fixBase64Images = {
    name: "Fix base64 images",
    run: async (database: Database) => {
        await database.write(async (writer) => {
            const items = await database.collections.get<ItemModel>(ITEMS).query().fetch()

            for (const item of items) {
                const editorBlocks = await item.editorBlocks.fetch()

                for (const editorBlock of editorBlocks) {
                    const url = getBase64ImageUrl(editorBlock)

                    if (url) {
                        const imageUrl = await upsertBase64Asset(database, item, url)

                        if (editorBlock.type === ELEMENT_IMAGE) {
                            await editorBlock.update((record) => {
                                // @ts-ignore
                                record.url = imageUrl
                                record.options = record.options
                                    ? { ...record.options, url: imageUrl }
                                    : undefined
                            })
                        } else {
                            // Deprecated editor block
                            await editorBlock.update((record) => {
                                // @ts-ignore
                                record.urlOriginal = imageUrl
                                // @ts-ignore
                                record.urlThumbnail = imageUrl
                                // @ts-ignore
                                record.url_320 = imageUrl
                                // @ts-ignore
                                record.url_1024 = imageUrl
                            })
                        }
                    }

                    if (!editorBlock?.children?.length) continue

                    const { children } = editorBlock
                    const updatedChildren = await extractBase64Images(database, item, children)
                    await editorBlock.update((record) => {
                        record.children = updatedChildren
                    })
                }
            }
        })
    },
}
