import { KeyboardCommandKey } from "@mui/icons-material"
import { Box, SxProps, Theme, Typography } from "@mui/material"
import { FC, useMemo } from "react"

interface ShortcutProps {
    size?: "small" | "medium"
}

export const ShortcutInfo: FC<ShortcutProps> = ({ size }) => {
    const isMac = useMemo(() => navigator.platform.toUpperCase().indexOf("MAC") >= 0, [])

    return (
        <Box sx={styles.keyContainer}>
            {isMac ? (
                <KeyboardCommandKey fontSize={size} />
            ) : (
                <Typography sx={styles.ctrlCopy}>
                    <code>ctrl</code>
                </Typography>
            )}
            <Typography variant="body2" fontWeight={500} lineHeight={"normal"}>
                K
            </Typography>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    keyContainer: {
        cursor: "default",
        py: 1.5,
        px: 1.25,
        display: { xs: "none", sm: "flex" },
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 1,
        background: (theme) => theme.palette.divider,
        boxShadow: (theme) =>
            theme.palette.mode === "dark"
                ? `0px -3px 0px 0px ${theme.palette.action.hover} inset`
                : `0px -3px 0px 0px ${theme.palette.divider} inset`,
        svg: {
            fontSize: 16,
            path: {
                fill: (theme) => theme.palette.text.secondary,
            },
        },
    },
    ctrlCopy: {
        fontSize: 12,
        fontWeight: "bold",
    },
}
