import { Box, SxProps, Theme, ToggleButton, ToggleButtonGroup, alpha } from "@mui/material"
import { FC } from "react"

interface SummaryLengthToggleProps {
    value: string
    onChange: (value: string) => void
}

export const SUMMARY_LENGTH_DIV_ID = "summary-length-div"

export const SummaryLengthToggle: FC<SummaryLengthToggleProps> = ({ value, onChange }) => {
    return (
        <ToggleButtonGroup
            value={value}
            exclusive
            onChange={(_, newValue) => newValue && onChange(newValue)}
            aria-label="summary length options"
            id={SUMMARY_LENGTH_DIV_ID}
        >
            <Box sx={styles.container}>
                <ToggleButton
                    disableRipple
                    value="concise"
                    aria-label="concise"
                    sx={styles.toggleButton}
                >
                    Concise
                </ToggleButton>
                <ToggleButton
                    disableRipple
                    value="detailed"
                    aria-label="detailed"
                    sx={styles.toggleButton}
                >
                    Detailed
                </ToggleButton>
            </Box>
        </ToggleButtonGroup>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        display: "inline-flex",
        backgroundColor: (theme) => theme.palette.divider,
        borderRadius: 1.5,
        padding: 0.5,
        alignItems: "center",
    },

    toggleButton: {
        lineHeight: "normal",
        minHeight: 0,
        fontWeight: 500,
        fontSize: { xs: 12, sm: 14 },
        py: { xs: 1.25, sm: 1.25 },
        px: { xs: 1.25, sm: 2.25 },
        border: "1px solid transparent",
        "&.Mui-selected": {
            borderColor: (theme) => alpha(theme.palette.text.primary, 0.2),
            borderRadius: 1,
            backgroundColor: (theme) => theme.palette.background.default,
            color: (theme) =>
                theme.palette.mode === "dark"
                    ? theme.palette.primary.contrastText
                    : theme.palette.text.primary,
            "&:hover": {
                backgroundColor: (theme) => theme.palette.background.default,
            },
        },
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}
