import { Box, Grid, Typography } from "@mui/material"
import { FC } from "react"
import { OnboardingStep } from "./OnboardingStep"
import { OnboardingSection } from "./hooks/useSteps"

interface Props {
    sections: OnboardingSection[]
}

export const OnboardingSections: FC<Props> = ({ sections }) => {
    return (
        <Grid container rowSpacing={2} justifyContent="center" mt={3}>
            <Grid item xs={12} sm={9} md={6}>
                {sections.map(({ title, steps }) => (
                    <Box key={title} mb={4}>
                        <Typography fontWeight={500} variant="h5">
                            {title}
                        </Typography>
                        {steps.map((step, index) => (
                            <OnboardingStep
                                key={step.title}
                                {...step}
                                step={steps.length > 1 ? index + 1 : undefined}
                            />
                        ))}
                    </Box>
                ))}
            </Grid>
        </Grid>
    )
}
