import CloseIcon from "@mui/icons-material/Close"
import {
    alpha,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Fade,
    SxProps,
    Theme,
    Typography,
} from "@mui/material"
import { SummaryLengthToggle, useIsMobile } from "@recall/common"
import { useSummaryLength } from "hooks/useSummaryLength"
import { FC, SyntheticEvent, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    SET_ADD_MODAL_TAB,
    SET_IMPORT_STATE,
    SET_IS_ADD_MODAL_OPEN,
} from "storage/redux/app/actionTypes"
import { isBookmarksImportComplete, isBookmarksImporting } from "storage/redux/app/selectors"
import { importEnum, ValidAddModalTab } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"
import {
    TAB_BOOKMARKS_IMPORT,
    TAB_ENTITY_SEARCH,
    TAB_URL_SEARCH,
} from "../../../../../storage/redux/app/constants"
import { TabsMenu } from "./tabs/TabsMenu"

export const AddDialog: FC = () => {
    const selectedTab = useSelector((state: RootState) => state.app.addModalState.tab)
    const isImporting = useSelector(isBookmarksImporting)
    const isImportingComplete = useSelector(isBookmarksImportComplete)

    const isMobile = useIsMobile()
    const { summaryLength, updateSummaryLength } = useSummaryLength()

    const dispatch = useDispatch()

    const setSelectedTab = (newValue: ValidAddModalTab) => {
        dispatch({ type: SET_ADD_MODAL_TAB, payload: newValue })
    }

    const handleTabChange = (_event: SyntheticEvent, newValue: ValidAddModalTab) => {
        setSelectedTab(newValue)
    }

    const handleClose = () => {
        dispatch({
            type: SET_IS_ADD_MODAL_OPEN,
            payload: false,
        })

        if (isImportingComplete) {
            dispatch({
                type: SET_IMPORT_STATE,
                payload: importEnum.CLOSED,
            })
        }
    }

    useEffect(() => {
        if (isImporting) setSelectedTab(TAB_BOOKMARKS_IMPORT)

        return () => {
            setSelectedTab(TAB_URL_SEARCH)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog
            open
            onClose={handleClose}
            maxWidth="sm"
            PaperProps={{
                sx: {
                    ...styles.paper,
                    ...(isMobile && { top: 0, margin: 0 }),
                },
            }}
            slotProps={{
                backdrop: {
                    sx: styles.backdrop,
                },
            }}
        >
            <DialogTitle sx={styles.dialogTitle}>
                <Typography sx={styles.title}>Create knowledge card</Typography>
                <Fade in={selectedTab !== TAB_ENTITY_SEARCH}>
                    <Box sx={styles.summaryLengthToggleWrapper}>
                        <SummaryLengthToggle value={summaryLength} onChange={updateSummaryLength} />
                    </Box>
                </Fade>
                {isMobile && (
                    <Box sx={styles.mobileCloseIcon} component={CloseIcon} onClick={handleClose} />
                )}
            </DialogTitle>
            <DialogContent sx={styles.dialogContent}>
                <TabsMenu
                    onClose={handleClose}
                    tabValue={selectedTab}
                    handleChange={handleTabChange}
                />
            </DialogContent>
        </Dialog>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    paper: {
        borderRadius: { xs: 0, sm: 3 },
        maxWidth: 800,
        width: "100%",
        maxHeight: { xs: "unset", sm: "90%" },
        height: { xs: "100%", sm: "unset" },
        bgcolor: (theme) => theme.palette.background.default,
        position: "fixed",
        top: "5%",
        my: 0,
    },
    backdrop: {
        bgcolor: (theme) => alpha(theme.palette.text.primary, 0.2),
        backdropFilter: "blur(5px)",
    },
    dialogTitle: {
        display: "flex",
        justifyContent: { xs: "flex-start", sm: "space-between" },
        alignItems: { xs: "flex-start", sm: "center" },
        flexDirection: { xs: "column", sm: "row" },
        p: (theme) => ({
            xs: theme.spacing(2.5, 3, 1.25, 3),
            sm: theme.spacing(3.75, 4.5, 2.5, 4.5),
        }),
    },
    title: {
        fontWeight: 600,
        mb: { xs: 2, sm: 0 },
        fontSize: { xs: "1.1rem", sm: "1.375rem" },
    },
    mobileCloseIcon: {
        position: "absolute",
        top: 20,
        right: 24,
        cursor: "pointer",
    },
    dialogContent: {
        height: 548,
        p: (theme) => ({
            xs: theme.spacing(4.5, 3, 0, 3),
            sm: theme.spacing(4.5, 4.5, 0, 4.5),
        }),
        overflow: "hidden",
    },
}
