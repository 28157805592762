import { useDatabase } from "@nozbe/watermelondb/react"
import { createEmptyItem, ItemModel, ItemPartial, itemRepository, WIKIPEDIA } from "@recall/common"
import { debounce, uniqBy } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { itemAPI } from "storage/api/items/Item"
import { RootState } from "storage/redux/rootReducer"

const SEARCH_DEBOUNCE_MS = 200
const SAVED_ITEMS_SEARCH_NUMBER = 10

export interface Result extends ItemPartial {
    isCreateOption?: boolean
}

export default function useSearchItems(value: string): {
    loading: boolean
    results: Result[]
} {
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState<Result[]>([])
    const language = useSelector((state: RootState) => state.user.searchLanguage)

    const db = useDatabase()

    const getSavedItems = async (searchValue: string) => {
        const searchedSavedItems = await itemRepository.search(db, searchValue, true)

        const savedItems = await Promise.all(
            searchedSavedItems.map(async (item: ItemModel) => await item.toItemPartial())
        )

        return savedItems
    }

    const getWikiSourceIdentifiers = (items: ItemPartial[]): Set<string> => {
        const wikiSourcesSet = new Set<string>()

        for (const item of items) {
            const wikiSource = item.sources.find((source) => source.name === WIKIPEDIA)

            if (!wikiSource) continue

            wikiSourcesSet.add(wikiSource.identifier)
        }

        return wikiSourcesSet
    }

    const getCreateItemOption = (searchValue: string) => {
        const emptyItem = createEmptyItem()
        const emptyItemPartial = {
            ...emptyItem,
            name: searchValue,
            isCreateOption: true,
        }

        return emptyItemPartial
    }

    const getUniqueItemsBySource = (
        savedItems: ItemPartial[],
        wikiItems: ItemPartial[],
        sourceName: string
    ) => {
        const savedItemsSourceIdentifiers = getWikiSourceIdentifiers(savedItems)

        const unsavedWikiItems = wikiItems.filter((item) => {
            const wikiSource = item.sources.find((source) => source.name === sourceName)
            return wikiSource && !savedItemsSourceIdentifiers.has(wikiSource.identifier)
        })

        const slicedSavedItems = savedItems.slice(0, SAVED_ITEMS_SEARCH_NUMBER)

        return [...slicedSavedItems, ...unsavedWikiItems]
    }

    const getAllSavedItems = async (savedItems: ItemPartial[], wikiItems: ItemPartial[]) => {
        const wikiSources = Array.from(getWikiSourceIdentifiers(wikiItems))
        const wikiSavedItems = await itemRepository.getItemsBySourceIdentifiers(db, wikiSources)
        const wikiSavedItemPartials = await Promise.all(
            wikiSavedItems.map((item) => item.toItemPartial())
        )
        const allSavedItems = uniqBy([...wikiSavedItemPartials, ...savedItems], "id")
        return allSavedItems
    }

    // eslint-disable-next-line
    const debouncedSearch = useCallback(
        debounce(async (searchValue: string) => {
            if (!searchValue) {
                setResults([])
                setLoading(false)
                return
            }

            const [savedItems, wikiItems] = await Promise.all([
                getSavedItems(searchValue),
                itemAPI.searchWikipedia(searchValue, language),
            ])

            const allSavedItems = await getAllSavedItems(savedItems, wikiItems)
            const searchedItems = getUniqueItemsBySource(allSavedItems, wikiItems, WIKIPEDIA)

            const createItemOption = getCreateItemOption(searchValue)
            setResults([createItemOption, ...searchedItems])

            setLoading(false)
        }, SEARCH_DEBOUNCE_MS),
        [db]
    )
    useEffect(() => {
        const searchValue = value.trim()

        if (searchValue === "") {
            setResults([])
            setLoading(false)
            return
        }

        setLoading(true)
        debouncedSearch(searchValue)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, debouncedSearch])

    return { loading, results }
}
