import {
    RESET_USER_PREMIUM_STATE,
    checkExtensionStatus,
    sendMessageToExtension,
} from "@recall/common"
import { AddDialog } from "components/layouts/components/Header/AddButton/AddDialog"
import { useIsSurveyCompleted } from "components/layouts/components/Survey/hooks/useIsSurveyCompleted"
import { useQueryParameter } from "hooks/useQueryParameter"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    SET_IS_SCRAPING_MODAL_OPEN,
    SET_IS_UPGRADE_MODAL_OPEN,
} from "storage/redux/app/actionTypes"
import { isScrapingModalOpen, isUpgradePlanModalOpen } from "storage/redux/app/selectors"
import { RootState } from "storage/redux/rootReducer"
import { ScrapingModal } from "./ScrapingModal"
import { ThankYouModal } from "./ThankYouModal"
import { UpgradePlanModal } from "./UpgradePlanModal"
import { useShortcutListener } from "./hooks/useShortcutListener"

const SHOW_THANK_YOU_MODAL_QUERY_PARAM = "show-thank-you-modal"

export const Modals = () => {
    const dispatch = useDispatch()

    const isAppLoading = useSelector((state: RootState) => state.app.appLoading)

    const { isPresent: isThankYouModalOpen, remove: closeThankYouModal } = useQueryParameter(
        SHOW_THANK_YOU_MODAL_QUERY_PARAM
    )

    const isAddModalOpen = useSelector((state: RootState) => state.app.addModalState.isOpen)
    const isUpgradeModalOpen = useSelector(isUpgradePlanModalOpen)
    const isScrapingExceededModalOpen = useSelector(isScrapingModalOpen)
    const { shouldShowSurvey, isLoading: isLoadingSurveys } = useIsSurveyCompleted()

    const closeUpgradeModal = () => {
        dispatch({ type: SET_IS_UPGRADE_MODAL_OPEN, payload: false })
    }

    const closeScrapingModal = () => {
        dispatch({ type: SET_IS_SCRAPING_MODAL_OPEN, payload: false })
    }

    const resetExtensionTier = async () => {
        const { isExtensionInstalled } = await checkExtensionStatus()
        if (isExtensionInstalled)
            sendMessageToExtension({
                type: RESET_USER_PREMIUM_STATE,
            })
    }

    useShortcutListener()

    useEffect(() => {
        if (isThankYouModalOpen) resetExtensionTier()
    }, [isThankYouModalOpen])

    if (isAppLoading || shouldShowSurvey || isLoadingSurveys) return null
    if (isThankYouModalOpen) return <ThankYouModal closeModal={closeThankYouModal} />
    if (isAddModalOpen) return <AddDialog />
    if (isUpgradeModalOpen) return <UpgradePlanModal closeModal={closeUpgradeModal} />
    if (isScrapingExceededModalOpen) return <ScrapingModal closeModal={closeScrapingModal} />

    return null
}
