import { OnboardingStepsEnum, useIsMobile } from "@recall/common"
import { useIsNative } from "hooks/useIsNative"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"

export const useOnboardingProgress = () => {
    const onboarding = useSelector((state: RootState) => state.user.onboarding)
    const isMobile = useIsMobile()
    const isNative = useIsNative()

    const mobileOnboardingSteps = [
        OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH,
        OnboardingStepsEnum.CREATE_LINK,
    ]

    const desktopOnboardingSteps = [
        OnboardingStepsEnum.INSTALL_EXTENSION,
        OnboardingStepsEnum.CREATE_CARD_WITH_EXTENSION,
        ...mobileOnboardingSteps,
    ]

    const nativeOnboardingSteps = [
        OnboardingStepsEnum.CREATE_CARD_WITH_SHARE_TARGET,
        ...mobileOnboardingSteps,
    ]

    const getStepsProgress = () => {
        if (!onboarding?.finished?.length) return 0

        let stepsToCheck = desktopOnboardingSteps

        if (isMobile) {
            stepsToCheck = mobileOnboardingSteps
        }

        if (isNative) {
            stepsToCheck = nativeOnboardingSteps
        }

        return (
            onboarding.finished.reduce((acc, step) => {
                if (stepsToCheck.includes(step)) return acc + 1

                return acc
            }, 0) / stepsToCheck.length
        )
    }

    const stepsProgress = useMemo(getStepsProgress, [onboarding]) // eslint-disable-line react-hooks/exhaustive-deps
    return { stepsProgress, isCompleted: stepsProgress === 1 }
}
