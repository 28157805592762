import { firebase, OnboardingStepsEnum, User } from "@recall/common"
import { uniq } from "lodash"
import { userRepository } from "repositories/userRepository"
import { RootState } from "../rootReducer"
import { SET_ONBOARDING, UPDATE_USER } from "./actionTypes"

export const setOnboarding =
    (onboarding: User["onboarding"], isReset = false) =>
    async (dispatch: Function, getState: Function) => {
        const state: RootState = getState()

        dispatch({
            type: SET_ONBOARDING,
            payload: isReset ? { ...onboarding, isOpen: false } : onboarding,
        })

        await userRepository.upsertUser(state.user.uid, {
            onboarding,
        })
    }

// Before using this function, check if the fields you're updating are the same on both the user object and in Redux.
export const updateUser = (payload: Partial<User>, uid: string) => async (dispatch: Function) => {
    dispatch({ type: UPDATE_USER, payload })
    if (!firebase.auth.currentUser || !uid) return
    await userRepository.upsertUser(uid, payload)
}

export const finishCreateCardInSearchOnboardingAction =
    () => (dispatch: Function, getState: () => RootState) => {
        const { onboarding } = getState().user

        if (
            !onboarding?.finished ||
            onboarding?.finished?.includes(OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH)
        )
            return

        dispatch(
            setOnboarding({
                ...onboarding,
                finished: uniq([
                    ...onboarding.finished,
                    OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH,
                ]),
            })
        )
    }
