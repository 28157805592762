import { Verified } from "@mui/icons-material"
import { alpha, Box, List, ListItemButton, SxProps, Theme, Typography } from "@mui/material"
import { Spinner } from "@recall/common"
import { ReactComponent as BookmarkIcon } from "assets/svg/bookmark_icon.svg"
import { FC } from "react"
import { FailedBookmark } from "storage/redux/app/types"
import { AddButtonFooter } from "../AddButtonFooter"

interface Props {
    isLoading: boolean
    created: number
    existing: number
    count: number
    failedBookmarks: FailedBookmark[]
    onClose: () => void
}

export const BookmarksLoader: FC<Props> = ({
    isLoading,
    created,
    existing,
    count,
    failedBookmarks,
    onClose,
}) => {
    const failedCount = failedBookmarks.length

    return (
        <>
            <Box sx={styles.outerContainer}>
                <Box
                    sx={{
                        ...styles.scrollContainer,
                        justifyContent: failedCount ? "flex-start" : "center",
                    }}
                >
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <Box mb={2}>
                            <Verified sx={{ width: 80, height: 80 }} color="secondary" />
                        </Box>
                    )}
                    <Typography fontSize={22} fontWeight="bold" mb={2} p={2}>
                        {isLoading ? "Importing bookmarks" : "Bookmarks are imported"}
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="column"
                        px={2}
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                    >
                        <Typography variant="body1" my={0}>
                            Created {created} of {count} cards.
                        </Typography>
                        {Boolean(existing) && (
                            <Typography variant="body1" my={1}>
                                {existing} {existing > 1 ? "cards" : "card"} already exist.
                            </Typography>
                        )}

                        {Boolean(failedCount) && (
                            <>
                                <Typography fontSize={22} fontWeight="bold" mt={3.5} mb={2}>
                                    Failed to create {failedCount}{" "}
                                    {failedCount > 1 ? "cards" : "card"}
                                </Typography>
                                <List sx={styles.failedList}>
                                    {failedBookmarks.map((failedBookmark, index) => (
                                        <ListItemButton
                                            key={`${failedBookmark.url}-${index}`}
                                            component="a"
                                            href={failedBookmark.url}
                                            target="_blank"
                                            sx={styles.listItemButton}
                                        >
                                            <Box sx={styles.failedBookmarkIcon}>
                                                <BookmarkIcon />
                                            </Box>

                                            <Typography
                                                variant="body2"
                                                color={(theme) => theme.palette.text.secondary}
                                            >
                                                {failedBookmark.url}
                                            </Typography>
                                        </ListItemButton>
                                    ))}
                                </List>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <AddButtonFooter onClose={onClose} cancelText={!isLoading ? "Cancel" : "Minimize"} />
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    outerContainer: {
        height: 336,
        p: 2,
        pt: 3,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 1.5,
    },
    scrollContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        mb: 6,
        overflow: "auto",
    },
    failedList: {
        width: "100%",
        borderRadius: 1,
        background: (theme) => alpha(theme.palette.divider, 0.05),
    },
    failedBookmarkIcon: {
        mr: 2,
        path: {
            fill: (theme) => theme.palette.text.primary,
        },
        display: "flex",
        alignItems: "center",
    },
    listItemButton: {
        mx: 2,
        borderRadius: 1,
    },
}
