import { FC } from "react"
import { ValidAddModalTab } from "storage/redux/app/types"
import {
    TAB_BOOKMARKS_IMPORT,
    TAB_ENTITY_SEARCH,
    TAB_PDF_UPLOAD,
    TAB_URL_SEARCH,
} from "../../../../../../storage/redux/app/constants"
import { EntitySearchTab } from "../EntitySearchTab"
import { PdfUploadTab } from "../PdfUploadTab"
import { URLSearchTab } from "../URLSearchTab/URLSearchTab"
import { BookmarksImportTab } from "../bookmarks/BookmarksImportTab"

interface Props {
    tabValue: ValidAddModalTab
    onClose: () => void
}

export const TabContent: FC<Props> = ({ tabValue, onClose }) => {
    switch (tabValue) {
        case TAB_URL_SEARCH:
            return <URLSearchTab onClose={onClose} />
        case TAB_ENTITY_SEARCH:
            return <EntitySearchTab onClose={onClose} />
        case TAB_PDF_UPLOAD:
            return <PdfUploadTab onClose={onClose} />
        case TAB_BOOKMARKS_IMPORT:
            return <BookmarksImportTab onClose={onClose} />
        default:
            return null
    }
}
