import { Check } from "@mui/icons-material"
import { Button } from "@mui/material"
import { keyframes, SxProps, Theme } from "@mui/material/styles"
import { Spinner } from "@recall/common"
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_ADD_MODAL_TAB, SET_IS_ADD_MODAL_OPEN } from "storage/redux/app/actionTypes"
import { TAB_BOOKMARKS_IMPORT } from "storage/redux/app/constants"
import { isBookmarksImporting } from "storage/redux/app/selectors"
import { isBookmarksImportComplete } from "storage/redux/app/selectors"

const BookmarksImportButtonComponent = () => {
    const dispatch = useDispatch()

    const isImporting = useSelector(isBookmarksImporting)
    const isImportingComplete = useSelector(isBookmarksImportComplete)

    const handleOpenBookmarkModal = () => {
        dispatch({ type: SET_IS_ADD_MODAL_OPEN, payload: true })
        dispatch({ type: SET_ADD_MODAL_TAB, payload: TAB_BOOKMARKS_IMPORT })
    }

    if (!isImporting && !isImportingComplete) return null

    return (
        <Button
            onClick={handleOpenBookmarkModal}
            variant="outlined"
            sx={styles.importBookmarksButton}
            endIcon={
                isImportingComplete ? (
                    <Check color="secondary" />
                ) : (
                    <Spinner size={20} thickness={4} />
                )
            }
        >
            {isImportingComplete ? "Bookmarks imported" : "Importing bookmarks"}
        </Button>
    )
}

const bump = keyframes`
    50% {
        transform: scale(1.1)
    }
    100% {
        transform: scale(1)
    }
`

const styles: Record<string, SxProps<Theme>> = {
    importBookmarksButton: {
        animation: `1s ${bump} ease`,
    },
}

export const BookmarksImportButton = memo(BookmarksImportButtonComponent)
