import { DEFAULT_LANGUAGE, DEFAULT_SEARCH_LANGUAGE, languages } from "../constants"
import { SummaryLengthEnum } from "../repositories"
import { HttpClient } from "../utils"
import { buildPath, buildUrl } from "../utils/httpClient/common"
import { ItemApi } from "./types"

export interface YouTubeData {
    url: string
    transcript: string[] | null
    includesSentenceTimestamps: boolean
    metaData: YouTubeMetaData
    summaryLength: SummaryLengthEnum
}

export interface YouTubeSectionsData {
    url: string
    sections: Section[] | null
    includesSentenceTimestamps: boolean
    metaData: YouTubeMetaData
    summaryLength: SummaryLengthEnum
}

export interface YouTubeMetaData {
    name: string
    author: string
    description: string
    imageUrl: string
}

export interface Section {
    heading: string
    sentences: string[]
    start: number
    end: number
}

export interface SearchPreview {
    title: string | null
    image: string | null
    description: string | null
}

const YOUTUBE_TRANSCRIPT_SUMMARY_PATH = "/scraper/youtube/"
const YOUTUBE_SECTIONS_SUMMARY_PATH = "/scraper/youtube/sections/"
const PAGE_SUMMARY_PATH = "/scraper/"
const PAGE_HTML_SUMMARY_PATH = "/scraper/encoded-html/"
const PDF_HTML_SUMMARY_PATH = "/scraper/pdf/encoded-html/"
const PDF_FILE_SUMMARY_PATH = "/scraper/pdf/upload/"
const WIKI_SUMMARY_PATH = "/items/get"
const WIKI_SUMMARY_SEARCH_PATH = "/items/search"
const SUMMARY_PREVIEW_PATH = "/search-preview/"
const EXPAND_SUMMARY_URL_PATH = "/expand_url/"
const INVALIDATE_SUMMARY_CACHE_PATH = "/cache/invalidate-html/"

interface Options {
    language?: keyof typeof languages | typeof DEFAULT_LANGUAGE | typeof DEFAULT_SEARCH_LANGUAGE
    isSaveInBackgroundAllowed?: boolean
    contentType?: string
}

export class SummariesApi {
    httpClient: HttpClient
    isSaveInBackgroundAllowed: boolean

    constructor(httpClient: HttpClient, isSaveInBackgroundAllowed = false) {
        this.httpClient = httpClient
        this.isSaveInBackgroundAllowed = isSaveInBackgroundAllowed
    }

    private getCost(response: Response) {
        return Number(response.headers.get("x-response-effort")) || null
    }

    private getIsCardSaved(response: Response) {
        return Boolean(response.headers.get("Is-Card-Saved-In-Background"))
    }

    private async getResponse<T>(response: Response) {
        const data: T = await response.json()
        const cost = this.getCost(response)
        const isCardSavedInBackground = this.getIsCardSaved(response)
        return { data, cost, isCardSavedInBackground }
    }

    getHeaders(options: Options) {
        const headers: HeadersInit = { "Content-Type": "application/json" }
        if (options.language) headers["Accept-Language"] = options?.language || DEFAULT_LANGUAGE
        if (options.isSaveInBackgroundAllowed || this.isSaveInBackgroundAllowed)
            headers["Allow-Save-In-Background"] = "true"
        if (options.contentType) headers["Content-Type"] = options.contentType

        return headers
    }

    async summarizeYT(payload: YouTubeData, options: Options) {
        const response = await this.httpClient.post(
            buildPath(YOUTUBE_TRANSCRIPT_SUMMARY_PATH, payload.url),
            JSON.stringify(payload),
            this.getHeaders(options)
        )
        return await this.getResponse<ItemApi>(response)
    }

    async summarizeYTWithSections(payload: YouTubeSectionsData, options: Options) {
        const response = await this.httpClient.post(
            buildPath(YOUTUBE_SECTIONS_SUMMARY_PATH, payload.url),
            JSON.stringify(payload),
            this.getHeaders(options)
        )
        return await this.getResponse<ItemApi>(response)
    }

    async summarizePage(url: string, summaryLength: SummaryLengthEnum, options: Options = {}) {
        const response = await this.httpClient.get(
            buildUrl(PAGE_SUMMARY_PATH, { url, summaryLength }),
            this.getHeaders(options)
        )
        return await this.getResponse<ItemApi>(response)
    }
    async summarizePageHtml(
        url: string,
        summaryLength: SummaryLengthEnum,
        encodedHtml: string,
        options: Options = {}
    ) {
        const response = await this.httpClient.post(
            buildPath(PAGE_HTML_SUMMARY_PATH, url),
            JSON.stringify({ url, encoded_html: encodedHtml, summaryLength }),
            this.getHeaders(options)
        )
        return await this.getResponse<ItemApi>(response)
    }

    async summarizePdfPageHtml(
        url: string,
        summaryLength: SummaryLengthEnum,
        encodedHtml: string,
        options: Options = {}
    ) {
        const response = await this.httpClient.post(
            buildPath(PDF_HTML_SUMMARY_PATH, url),
            JSON.stringify({ url, encoded_html: encodedHtml, summaryLength }),
            this.getHeaders(options)
        )
        return await this.getResponse<ItemApi>(response)
    }

    async summarizePdfFile(
        url: string,
        name: string,
        summaryLength: SummaryLengthEnum,
        formData: FormData,
        options: Options = {}
    ) {
        const headers = this.getHeaders(options)

        delete headers["Content-Type"]

        const response = await this.httpClient.post(
            buildUrl(PDF_FILE_SUMMARY_PATH, { url, name, summaryLength }),
            formData,
            headers
        )
        return await this.getResponse<ItemApi>(response)
    }

    async summarizeWikipediaPage(
        slug: string,
        language = DEFAULT_SEARCH_LANGUAGE
    ): Promise<ItemApi> {
        const response = await this.httpClient.get(
            buildUrl(WIKI_SUMMARY_PATH, { query: slug, language })
        )
        return await response.json()
    }

    async findWikipediaSummary(
        query: string,
        language = DEFAULT_SEARCH_LANGUAGE
    ): Promise<ItemApi[]> {
        const response = await this.httpClient.get(
            buildUrl(WIKI_SUMMARY_SEARCH_PATH, { query, language })
        )
        return await response.json()
    }

    async getSummaryPreview(url: string): Promise<SearchPreview> {
        const response = await this.httpClient.get(buildUrl(SUMMARY_PREVIEW_PATH, { url }))
        return await response.json()
    }

    async expandSummaryUrl(url: string): Promise<string | null> {
        const response = await this.httpClient.get(buildUrl(EXPAND_SUMMARY_URL_PATH, { url }))
        const data = await response.json()
        return null || data?.url
    }

    async invalidateSummaryCache(
        url: string,
        summaryLength: SummaryLengthEnum,
        encodedHtml: string,
        options: Options = {}
    ) {
        await this.httpClient.post(
            buildPath(INVALIDATE_SUMMARY_CACHE_PATH, url),
            JSON.stringify({ url, summaryLength, encoded_html: encodedHtml }),
            this.getHeaders(options)
        )
    }
}
