import { Box, SxProps, Theme, Typography } from "@mui/material"
import {
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    RECALL_EXTENSION_STORE_URL,
    isFirefox,
} from "@recall/common"
import { InstallExtensionButton } from "components/shared/buttons/InstallExtensionButton"

export const InstallExtension = () => {
    return (
        <Box sx={styles.container}>
            <Typography variant="h5" fontWeight="bold">
                Install extension
            </Typography>
            <Typography
                variant="body2"
                textAlign="center"
                color="text.secondary"
                py={2}
                px={5}
                mb={4}
            >
                In order to import your bookmarks, you must first install <br /> The Recall Browser
                Extension.
            </Typography>
            <Box sx={styles.cta}>
                <InstallExtensionButton
                    href={
                        !isFirefox()
                            ? RECALL_EXTENSION_STORE_URL
                            : FIREFOX_RECALL_EXTENSION_STORE_URL
                    }
                    target="_blank"
                />
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    container: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        pb: 3,
    },
    cta: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}
