import { dbUtils, schemaService, useTabFocus } from "@recall/common"
import { SERVICE_WORKER } from "constants/serviceWorkers"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { userRepository } from "repositories/userRepository"
import { RootState } from "storage/redux/rootReducer"
import { SET_DID_UPDATE } from "storage/redux/user/actionTypes"
import { database } from "storage/watermelon/database"
import * as serviceWorker from "../serviceWorker"

export const useServiceWorker = () => {
    const [shouldReload, setShouldReload] = useState(false)
    const uid = useSelector((state: RootState) => state.user.uid)
    const didUpdate = useSelector((state: RootState) => state.user.didUpdate)

    const dispatch = useDispatch()

    const shouldCheckForUpdates = () => {
        const lastCheck = localStorage.getItem(SERVICE_WORKER.STORAGE_KEYS.LAST_CHECK)
        const now = Date.now()
        return !lastCheck || now - parseInt(lastCheck) > SERVICE_WORKER.CHECK_INTERVAL
    }

    const reload = async () => {
        localStorage.setItem(SERVICE_WORKER.STORAGE_KEYS.RELOAD, Date.now().toString())
        window.onbeforeunload = null
        dispatch({ type: SET_DID_UPDATE, payload: true })
        window.location.reload()
    }

    const checkForUpdates = useCallback(() => {
        if (shouldReload && !document.hidden) {
            reload()
            return
        }

        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.getRegistration().then((registration) => {
                if (!registration || !shouldCheckForUpdates()) return
                registration.update()
                localStorage.setItem(SERVICE_WORKER.STORAGE_KEYS.LAST_CHECK, Date.now().toString())
            })
        }
    }, [shouldReload])

    const handleOnUpdate = useCallback((registration: ServiceWorkerRegistration) => {
        const waitingServiceWorker = registration.waiting

        if (!waitingServiceWorker.postMessage) return

        waitingServiceWorker.addEventListener("statechange", async (event) => {
            if ((event.target as ServiceWorker).state !== "activated") return
            const hasUnsyncedChanges = await dbUtils.checkUnsyncedChanges(database)

            if (document.hidden) {
                setShouldReload(true)
                return
            }

            const user = await userRepository.getUser(uid)
            const isVersionUpToDate = Boolean(uid) && (await schemaService.isVersionUpToDate(user))

            if (!hasUnsyncedChanges || !isVersionUpToDate) {
                reload()
                return
            }

            const interval = setInterval(async () => {
                const hasUnsyncedChanges = await dbUtils.checkUnsyncedChanges(database)

                if (!hasUnsyncedChanges) {
                    clearInterval(interval)
                    reload()
                }
            }, 300)
        })

        waitingServiceWorker.postMessage({ type: SERVICE_WORKER.MESSAGES.SKIP_WAITING })
    }, [])

    useTabFocus(checkForUpdates)

    useEffect(() => {
        if (didUpdate) {
            toast("A new version of Recall has been loaded.", { type: "success" })
            dispatch({ type: SET_DID_UPDATE, payload: false })
        }

        const handleStorageChange = (e: StorageEvent) => {
            const isReloadEvent = e.key === SERVICE_WORKER.STORAGE_KEYS.RELOAD
            if (!isReloadEvent) return
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        }
        window.addEventListener("storage", handleStorageChange)
        serviceWorker.register({ onUpdate: handleOnUpdate })

        return () => {
            window.removeEventListener("storage", handleStorageChange)
        }
    }, [handleOnUpdate])
}
