import { Box } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { ItemModel, itemRepository, Result, SearchResults, sentry, WIKIPEDIA } from "@recall/common"
import { ReactComponent as GoogleIcon } from "assets/social/google_sm.svg"
import { ReactComponent as WikidataIcon } from "assets/social/wikidata.svg"
import { ReactComponent as WikipediaIcon } from "assets/social/wikipedia_sm.svg"
import { useOpenOnboarding } from "components/layouts/components/Onboarding/hooks/useOpenOnboarding"
import SearchInputComp from "components/shared/inputs/SearchInput"
import { RERENDER_TAGS_EVENT } from "components/shared/tags/hooks/useGroupedTags"
import { useOpenItem } from "hooks/items/useOpenItem"
import useSearchItems from "hooks/useSearchItems"
import { FC, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { itemAPI } from "storage/api/items/Item"
import { SET_LOADING, SET_SEARCH_TERM } from "storage/redux/app/actionTypes"
import { RootState } from "storage/redux/rootReducer"
import { finishCreateCardInSearchOnboardingAction } from "storage/redux/user/actions"
import { AddButtonFooter } from "./AddButtonFooter"
import { SupportList } from "./SupportList"

const DATASOURCES = [
    { icon: <WikipediaIcon />, label: "Wikipedia" },
    { icon: <GoogleIcon />, label: "Google Knowledge Graph" },
    { icon: <WikidataIcon width={22} />, label: "Wikidata" },
]

interface Props {
    onClose: () => void
}

export const EntitySearchTab: FC<Props> = ({ onClose }) => {
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)
    const searchTerm = useSelector((state: RootState) => state.app.searchTerm)
    const { openItem } = useOpenItem()
    const db = useDatabase()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [error, setError] = useState<string | null>(null)
    const { closeOnboarding } = useOpenOnboarding()
    const { loading, results } = useSearchItems(searchTerm)

    useEffect(() => {
        setSearchTerm("")
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setSearchTerm = (term: string) => {
        dispatch({ type: SET_SEARCH_TERM, payload: term })
    }

    const handleSubmit = async () => {
        if (searchTerm.length === 0) {
            setError("Please enter a search term.")
            return
        }
        if (selectedIndex >= results.length) {
            setError("Please select a valid result.")
            return
        }

        const result = results[selectedIndex]
        await handleSelectResult(result)
    }

    const handleSelectResult = async (result: Result) => {
        if (result === null) return null

        onClose()
        closeOnboarding()

        const item = await fetchItem(result)

        if (!item) return null

        const tagsCount = await item.tags.count

        if (tagsCount) {
            document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
        }

        openItem(item)
    }

    const fetchItem = async (result: Result): Promise<ItemModel | null> => {
        dispatch({ type: SET_LOADING, payload: true })
        try {
            if (result?.isCreateOption) {
                return await itemRepository.createFull(db, result)
            } else if (result.isSaved) {
                return await itemRepository.get(db, result.id)
            } else {
                const item = await itemRepository.getBySources(db, result.sources, true)
                if (item) return item

                const wikipediaSource = result.sources.find((source) => source.name === WIKIPEDIA)
                if (wikipediaSource) {
                    const item = await itemAPI.getAndSaveWikipedia(
                        db,
                        wikipediaSource.identifier,
                        result.language
                    )
                    if (item) await item.setIsExpanded(true)
                    return item
                }
            }
        } catch (e) {
            sentry.captureException(e)
        } finally {
            dispatch({ type: SET_LOADING, payload: false })
            dispatch(finishCreateCardInSearchOnboardingAction())
        }

        return null
    }

    const isOpen = searchTerm.length > 0

    return (
        <>
            <SearchInputComp
                value={searchTerm}
                onChange={setSearchTerm}
                onPressEnter={handleSubmit}
                placeholder="Search anything"
                error={error}
            />
            <Box ref={ref}>
                {ref.current && (
                    <SearchResults
                        open={isOpen}
                        value={searchTerm}
                        anchorEl={ref.current}
                        onClose={onClose}
                        loading={loading}
                        results={results}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        onSelectResult={handleSelectResult}
                    />
                )}
            </Box>
            <SupportList title="From predefined data sources" supportedSources={DATASOURCES} />
            <AddButtonFooter onClick={handleSubmit} onClose={onClose} />
        </>
    )
}
