import { checkExtensionStatus, useTabFocus } from "@recall/common"
import { useEffect, useState } from "react"
import { useIsMobile } from "./useIsMobile"

export const useShouldInstallExtension = () => {
    const [isLoading, setIsLoading] = useState(false)
    const isMobile = useIsMobile()
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(true)

    const getExtensionStatus = async () => {
        if (isMobile) return
        setIsLoading(true)
        const { isExtensionInstalled } = await checkExtensionStatus()
        setIsExtensionInstalled(isExtensionInstalled)

        setIsLoading(false)
    }

    useEffect(() => {
        getExtensionStatus()
        // eslint-disable-next-line
    }, [])

    useTabFocus(getExtensionStatus)

    return { shouldInstallExtension: !isExtensionInstalled && !isMobile, isLoading }
}
