import { Box, SxProps, Theme } from "@mui/material"
import { FC, PropsWithChildren } from "react"

interface Props {
    sx?: SxProps<Theme>
}

export const NoticeBox: FC<PropsWithChildren<Props>> = ({ children, sx }) => {
    const style = { ...styles.noticeBox, ...sx } as SxProps<Theme>

    return <Box sx={style}>{children}</Box>
}

const styles: Record<string, SxProps<Theme>> = {
    noticeBox: {
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        borderRadius: 1,
        py: 1,
        px: 2,
    },
}
