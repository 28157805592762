import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { getTheme } from "@recall/common"
import { Routes } from "components/routes/Routes"
import RecallToastContainer from "components/shared/RecallToastContainer"
import { useAuthenticate } from "hooks/auth/useAuthenticate"
import { useDbSync } from "hooks/sync/useDbSync"
import { useMessageListener } from "hooks/useMessageListener"
import { useNewDomain } from "hooks/useNewDomain"
import { useServiceWorker } from "hooks/useServiceWorker"
import useThemeMode from "hooks/useThemeMode"
import React, { memo, useEffect } from "react"
import "react-toastify/dist/ReactToastify.css"
import smoothscroll from "smoothscroll-polyfill"
import "./App.css"
import { useGoogleTagManager } from "hooks/useGoogleTagManager"

smoothscroll.polyfill()

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
    useNewDomain()
    useMessageListener()
    useDbSync()
    useAuthenticate()
    useServiceWorker()

    return <AppContent />
}

const AppContent = memo(() => {
    const themeMode = useThemeMode()
    const theme = getTheme(themeMode)
    useGoogleTagManager()

    useEffect(() => {
        const themeColor = theme.palette.background.layout
        document.querySelector("meta[name='theme-color']")?.setAttribute("content", themeColor)
    }, [theme.palette.background.layout])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <RecallToastContainer />
            <Routes />
        </ThemeProvider>
    )
})

export default App
