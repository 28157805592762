import { COMPLETE_TUTORIAL_EVENT, SEARCH_SHORTCUT_USED } from "constants/events"

import { posthogService, tutorials } from "@recall/common"
import { isHotkey } from "is-hotkey"

import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { SET_IS_ADD_MODAL_OPEN } from "storage/redux/app/actionTypes"
import { TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"

export const useShortcutListener = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        const handleListenShortcut = (e: KeyboardEvent) => {
            if (isHotkey("cmd+k", e) || isHotkey("ctrl+k", e)) {
                posthogService.captureEvent(SEARCH_SHORTCUT_USED)
                dispatch({ type: SET_IS_ADD_MODAL_OPEN, payload: true })
                completeTutorial()
            }
        }

        document.addEventListener("keydown", handleListenShortcut)

        return () => {
            document.removeEventListener("keydown", handleListenShortcut)
        }
        // eslint-disable-next-line
    }, [])

    const completeTutorial = () => {
        dispatch({ type: TUTORIAL_COMPLETE, payload: tutorials.ITEMS_INTRO })
        posthogService.captureEvent(COMPLETE_TUTORIAL_EVENT, {
            tutorialName: tutorials.ITEMS_INTRO,
        })
    }
}
