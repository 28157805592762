export const SERVICE_WORKER = {
    CHECK_INTERVAL: 15 * 60 * 1000, // 15 minutes
    UPDATE_CHECK_INTERVAL: 1000,
    STORAGE_KEYS: {
        LAST_CHECK: "sw_last_check",
        RELOAD: "app_reload",
    },
    MESSAGES: {
        SKIP_WAITING: "SKIP_WAITING",
    },
} as const
