import { combineReducers } from "redux"
import { createMigrate, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { storageService } from "services/storageService"
import { appReducer } from "./app/reducers"
import { AppState } from "./app/types"
import { drawerReducer } from "./drawer/reducers"
import { DrawerState } from "./drawer/types"
import { itemsReducer } from "./items/reducers"
import { ItemsState } from "./items/types"
import { migrations } from "./migrations"
import { userReducer } from "./user/reducers"
import { UserState } from "./user/types"

export const LOGOUT_ACTION = "LOGOUT_ACTION"
export interface RootState {
    items: ItemsState
    user: UserState
    drawer: DrawerState
    app: AppState
}

const appBlacklist: (keyof AppState)[] = [
    "appLoading",
    "addModalState",
    "visibleItems",
    "scrollPosition",
    "userLoading",
    "questionsLoadingItemIds",
    "breadcrumbs",
    "isInitialSyncFinished",
    "loading",
    "isCreatingExtensionItems",
    "searchTerm",
    "bookmarks",
    "importState",
]

const combinedReducer = combineReducers({
    app: persistReducer(
        {
            key: "app",
            storage,
            blacklist: appBlacklist,
        },
        appReducer
    ),
    items: itemsReducer,
    user: userReducer,
    drawer: drawerReducer,
})

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_ACTION) {
        storage.removeItem("persist:root")
        storageService.clear()
        return combinedReducer(undefined, action)
    }

    return combinedReducer(state, action)
}

const persistConfig = {
    key: "root",
    storage,
    version: 32,
    migrate: createMigrate(migrations, { debug: false }),
    blacklist: ["app"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default persistedReducer
