import { Database } from "@nozbe/watermelondb"
import {
    assetRepository,
    connectionRepository,
    firebase,
    isDevelopment,
    itemRepository,
    posthogService,
    schemaService,
    sentry,
    sourceRepository,
    syncRealTimeDb,
    tagRepository,
} from "@recall/common"
import { initializeRootTag } from "utils/config"
import { executeInOneTabOnly } from "utils/locks"
import { migrate } from "../dataMigrations"

import { SCHEMA_VERSION_OUTDATED } from "constants/events"
import { userRepository } from "repositories/userRepository"
import { fixMigration34Issue } from "./migrations"

export const SYNC_TIME_MS = 2000

interface SyncingParams {
    db: Database
    uid: string
    handleFinish: Function
    handlePushCallback?: Function
    handlePullCallback?: Function
    isInitial?: boolean
}

const deleteStaleModels = async (db: Database) => {
    await sourceRepository.deleteStale(db)
    await connectionRepository.deleteStale(db)
    await itemRepository.deleteUnsaved(db)
    await itemRepository.deleteStale(db)
    await assetRepository.deleteStale(db)
    await tagRepository.deleteStaleByDate(db)
}

const checkIsAppUpdateRequired = async (uid: string): Promise<boolean> => {
    const user = await userRepository.getUser(uid)
    const isVersionUpToDate = await schemaService.isVersionUpToDate(user)
    if (!isVersionUpToDate && "serviceWorker" in navigator) {
        posthogService.captureEvent(SCHEMA_VERSION_OUTDATED)
        const registration = await navigator.serviceWorker.getRegistration()
        if (!registration) return false
        await registration.update()
        return true
    }
    return false
}

export const syncDatabase = async ({
    db,
    uid,
    handleFinish = () => {},
    handlePullCallback,
    handlePushCallback,
    isInitial = false,
}: SyncingParams) => {
    try {
        if (!firebase.auth.currentUser) return

        const isAppUpdateRequired = await checkIsAppUpdateRequired(uid)
        if (isAppUpdateRequired) return

        if (!isDevelopment()) {
            await syncRealTimeDb(db, uid, handlePushCallback, handlePullCallback)
        }

        if (isInitial) {
            await initializeRootTag(db)
            await deleteStaleModels(db)
            await executeInOneTabOnly(async () => await migrate(db), "migrations")
            await fixMigration34Issue(db, uid, handlePushCallback, handlePullCallback)
        }
    } catch (err) {
        sentry.captureException(err as Error)
    } finally {
        handleFinish()
    }
}
