import {
    TAB_BOOKMARKS_IMPORT,
    TAB_EMPTY_CARD,
    TAB_ENTITY_SEARCH,
    TAB_PDF_UPLOAD,
    TAB_URL_SEARCH,
} from "./constants"

export interface BreadcrumbItem {
    path: string
    label: string
    position?: number
}

export interface FailedBookmark {
    name: string
    url: string
}

export interface BookmarksImportState {
    created: number
    existing: number
    count: number
    failedBookmarks: FailedBookmark[]
}

export const VALID_ADD_MODAL_TABS = [
    TAB_URL_SEARCH,
    TAB_BOOKMARKS_IMPORT,
    TAB_PDF_UPLOAD,
    TAB_ENTITY_SEARCH,
    TAB_EMPTY_CARD,
] as const

export type ValidAddModalTab = (typeof VALID_ADD_MODAL_TABS)[number]

export interface AddModalState {
    isOpen: boolean
    tab: ValidAddModalTab
}

export interface AppState {
    platform: "web" | "ios" | "android"
    isNative: boolean
    themeMode: themeModeEnum
    loading: boolean
    appLoading: boolean
    userLoading: boolean
    extensionState: extensionStateEnum
    importState: importEnum
    addModalState: AddModalState
    isUpgradePlanModalOpen: boolean
    isScrapingModalOpen: boolean
    visibleItems: number
    scrollPosition: number
    questionsLoadingItemIds: string[]
    breadcrumbs: BreadcrumbItem[]
    isInitialSyncFinished: boolean
    isCreatingExtensionItems: boolean
    searchTerm: string
    bookmarks: BookmarksImportState
}

export enum themeModeEnum {
    LIGHT = "light",
    SYSTEM = "system",
    DARK = "dark",
}

export enum importEnum {
    CLOSED = "closed",
    IMPORTING = "importing",
    IMPORTING_COMPLETE = "importing-complete",
}

export enum extensionStateEnum {
    NOT_LOGGED_IN = "not-logged-in",
    LOGGED_IN = "logged-in",
    LOADING = "loading",
    NOT_INSTALLED = "not-installed",
}
