import CloseIcon from "@mui/icons-material/Close"
import { Box, Button, Grid, Grow, IconButton, Paper, Typography, useTheme } from "@mui/material"
import { posthogService } from "@recall/common"
import { RANDOM_SEARCH_CLICKED_EVENT } from "constants/events"
import { useItemsCount } from "hooks/items/useItemsCount"
import { FC } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    SET_ADD_MODAL_TAB,
    SET_IS_ADD_MODAL_OPEN,
    SET_SEARCH_TERM,
} from "storage/redux/app/actionTypes"
import { TAB_ENTITY_SEARCH } from "storage/redux/app/constants"
import { RootState } from "storage/redux/rootReducer"
import { TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"

export const SEARCH_TERMS = [
    "Drew Houston",
    "Naval Ravikant",
    "Fight Club",
    "Ethereum",
    "Solana blockchain",
    "Proof of Stake",
    "MetaMask",
    "Polkadot crypto",
    "Top Gun: Maverick",
    "The Batman",
    "The Adam Project",
    "Free Guy",
    "Deadpool",
    "David A. Sinclair",
    "Lex Fridman",
    "Andrew Huberman",
    "Sam Harris",
    "Peter Attia",
    "Breaking Bad",
    "WestWorld",
    "Dune (2021 film)",
    "Blood brain barrier",
    "Autophagy",
    "Ketosis",
    "Magnus Carlsen",
    "Hava Siegelmann",
    "Robert Cecil Martin",
    "Eric Evans",
    "Seth Godin",
    "Chamath Palihapitiya",
    "Guido van Rossum",
    "Tim Berners-Lee",
    "Linus Torvalds",
    "Chamath Palihapitiya",
    "Vitalik Buterin",
    "Martin Fowler",
]

function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const newUserMessageTutorialName = "newUserMessageTutorial"

export const NewUserMessage = () => {
    const dispatch = useDispatch()
    const includeReferences = useSelector(
        (state: RootState) => state.drawer.typeSection.inlcudeReferences
    )
    const itemsCount = useItemsCount(includeReferences)

    const handleDismiss = () => {
        dispatch({ type: TUTORIAL_COMPLETE, payload: newUserMessageTutorialName })
    }

    const handleClick = () => {
        posthogService.captureEvent(RANDOM_SEARCH_CLICKED_EVENT)

        dispatch({ type: SET_IS_ADD_MODAL_OPEN, payload: true })
        dispatch({ type: SET_ADD_MODAL_TAB, payload: TAB_ENTITY_SEARCH })

        const randomInt = randomIntFromInterval(0, SEARCH_TERMS.length - 1)
        const randomSearchTerm = SEARCH_TERMS[randomInt]

        setTimeout(() => {
            dispatch({ type: SET_SEARCH_TERM, payload: randomSearchTerm })
        }, 100)
    }

    if (itemsCount !== 0) return null

    return (
        <CardRandomSearch
            handleClick={handleClick}
            handleDismiss={itemsCount > 0 && handleDismiss}
        />
    )
}

interface CardRandomSearchProps {
    handleClick: () => void
    handleDismiss?: () => void
}

export const CardRandomSearch: FC<CardRandomSearchProps> = ({ handleDismiss, handleClick }) => {
    const theme = useTheme()

    const isDark = theme.palette.mode === "dark"

    return (
        <Grow in={true}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    mt: 4,
                }}
            >
                <Grid item xs={12} md={8} lg={6} xl={4}>
                    <Paper
                        elevation={5}
                        sx={{
                            p: 1,
                            mx: 2,
                            overflow: "hidden",
                            bgcolor: "background.default",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                p: 1,
                            }}
                        >
                            <Typography
                                variant="h4"
                                component="h4"
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                Start remembering
                            </Typography>
                            {handleDismiss && (
                                <IconButton
                                    onClick={handleDismiss}
                                    size={"small"}
                                    sx={{
                                        color: "primary.contrastText",
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </Box>
                        <Typography
                            component="div"
                            variant="subtitle1"
                            sx={{
                                px: 1,
                                py: 2,
                            }}
                        >
                            <Box>
                                You can use Recall for anything you want to remember. Here are a
                                couple ideas of where to start:
                            </Box>
                            <Box pt={2} pl={1}>
                                <Box>🎥 Movies or shows you want to watch or have enjoyed.</Box>
                                <Box>📚 Books you have learnt from and want to remember.</Box>
                                <Box>🗺️ Places you want to go.</Box>
                                <Box>📅 Events and history you are interested in. </Box>
                                <Box>👩 People who inspire you.</Box>
                            </Box>
                            <Box component={"p"}>
                                Can't think of anything? Click the button below:
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Box
                                    sx={{
                                        px: 0.5,
                                        py: 0.5,
                                    }}
                                >
                                    <Button
                                        onClick={handleClick}
                                        variant="outlined"
                                        color={isDark ? "secondary" : "primary"}
                                        sx={{
                                            minWidth: "100px",
                                            textTransform: "none",
                                            boxShadow: "none",
                                            fontSize: "17px",
                                        }}
                                    >
                                        Random search
                                    </Button>
                                </Box>
                            </Box>
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Grow>
    )
}
