import { Box, Link, SxProps, Tab, Tabs, Theme } from "@mui/material"
import {
    createEmptyItem,
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    isFirefox,
    itemRepository,
    posthogService,
    RECALL_EXTENSION_STORE_URL,
    Spinner,
    useIsMobile,
} from "@recall/common"
import { FC, ReactElement, SyntheticEvent, useEffect, useMemo } from "react"

import BookmarksIcon from "@mui/icons-material/BookmarksOutlined"
import { useDatabase } from "@nozbe/watermelondb/react"

import { ReactComponent as AddIcon } from "assets/svg/add_icon.svg"
import {
    TAB_BOOKMARKS_IMPORT,
    TAB_EMPTY_CARD,
    TAB_ENTITY_SEARCH,
    TAB_PDF_UPLOAD,
    TAB_URL_SEARCH,
} from "../../../../../../storage/redux/app/constants"

import { ReactComponent as ExtensionIcon } from "assets/svg/extension.svg"
import { ReactComponent as LinkIcon } from "assets/svg/link.svg"
import { ReactComponent as SearchIcon } from "assets/svg/search.svg"
import { ReactComponent as UploadIcon } from "assets/svg/upload.svg"
import { BOOKMARKS_IMPORT_OPEN, CREATE_BLANK_CARD_CLICKED } from "constants/events"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useShouldInstallExtension } from "hooks/useShouldInstallExtension"
import { ValidAddModalTab } from "storage/redux/app/types"
import { TabContent } from "./TabContent"

interface TabItem {
    name: string
    value: string
    icon: ReactElement
    isHidden?: boolean
    isExternal?: boolean
    href?: string
    onClick?: (e: MouseEvent) => void
}

interface Props {
    tabValue: ValidAddModalTab
    onClose: () => void
    handleChange: (event: SyntheticEvent, newValue: string) => void
}

export const TabsMenu: FC<Props> = ({ onClose, tabValue, handleChange }) => {
    const db = useDatabase()
    const { openItem } = useOpenItem()

    const isMobile = useIsMobile()

    const { shouldInstallExtension, isLoading: isExtensionLoading } = useShouldInstallExtension()

    const handleCreateBlank = async (e) => {
        e.stopPropagation()
        posthogService.captureEvent(CREATE_BLANK_CARD_CLICKED)
        const item = await itemRepository.createFull(db, createEmptyItem())
        openItem(item)
        onClose()
    }

    useEffect(() => {
        if (isExtensionLoading) return

        posthogService.captureEvent(BOOKMARKS_IMPORT_OPEN, {
            isExtensionInstalled: !shouldInstallExtension,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExtensionLoading])

    const tabs = useMemo((): TabItem[] => {
        return [
            {
                name: "URL",
                value: TAB_URL_SEARCH,
                icon: <LinkIcon />,
            },
            {
                name: "Search",
                value: TAB_ENTITY_SEARCH,
                icon: <SearchIcon />,
            },
            {
                name: "Upload",
                value: TAB_PDF_UPLOAD,
                icon: <UploadIcon />,
            },
            {
                name: "Bookmarks",
                value: TAB_BOOKMARKS_IMPORT,
                icon: <BookmarksIcon />,
                isHidden: isMobile,
            },
            {
                name: "Empty card",
                value: TAB_EMPTY_CARD,
                icon: <AddIcon />,
                onClick: handleCreateBlank,
            },
            !isMobile &&
                shouldInstallExtension && {
                    name: "Extension",
                    value: "extension",
                    icon: <ExtensionIcon />,
                    isExternal: true,
                    href: isFirefox()
                        ? FIREFOX_RECALL_EXTENSION_STORE_URL
                        : RECALL_EXTENSION_STORE_URL,
                },
        ].filter(Boolean) as TabItem[]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, db, openItem, shouldInstallExtension])

    return (
        <>
            {isExtensionLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Spinner />
                </Box>
            ) : (
                <>
                    <Box sx={styles.tabsWrapper}>
                        <Tabs
                            variant="scrollable"
                            scrollButtons={isMobile}
                            allowScrollButtonsMobile={isMobile}
                            TabIndicatorProps={{ sx: styles.tabIndicator }}
                            sx={styles.tabs}
                            value={tabValue}
                            onChange={handleChange}
                        >
                            {tabs.map((tab) => {
                                if (tab.isHidden) return null

                                return (
                                    <Tab
                                        key={tab.value}
                                        value={tab.value}
                                        icon={tab.icon}
                                        iconPosition="start"
                                        label={tab.name}
                                        onClick={tab.onClick}
                                        disableRipple
                                        {...(tab.isExternal && {
                                            LinkComponent: Link,
                                            href: tab.href,
                                            target: "_blank",
                                            onClick: (e) => handleChange(e, tabValue),
                                        })}
                                    />
                                )
                            })}
                        </Tabs>
                    </Box>
                    <Box sx={styles.tabContent}>
                        <TabContent tabValue={tabValue} onClose={onClose} />
                    </Box>
                </>
            )}
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    tabs: {
        minHeight: "unset",
        "& .MuiTabScrollButton-root": {
            py: 1,
            width: "unset",
        },
        "& .MuiTab-root": {
            p: { xs: 1.5, sm: 1.5, md: 2 },
            color: (theme) => theme.palette.action.active,
            fontSize: { xs: 12, sm: 13, md: 14 },
            fontWeight: "500",
            minHeight: "unset",
            minWidth: "unset",
            svg: {
                height: { xs: 16, sm: 18, md: 20 },
                width: { xs: 16, sm: 18, md: 20 },
                path: {
                    fill: (theme) => theme.palette.action.active,
                },
            },
        },
        "& .Mui-selected": {
            color: (theme) => `${theme.palette.text.primary} !important`,
            svg: {
                path: {
                    fill: (theme) => theme.palette.text.primary,
                },
            },
        },
        "& .MuiTabs-scrollButtons.Mui-disabled": {
            display: "none",
        },
    },
    tabsWrapper: {
        width: "100%",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    },
    tabContent: {
        mt: 3.75,
        overflow: "hidden",
    },
    tabIndicator: {
        color: (theme) => theme.palette.text.primary,
        backgroundColor: (theme) => theme.palette.text.primary,
    },
}
